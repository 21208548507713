<template>
    <div class="autocomplete">
        <input type="text" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"             
            placeholder="Start typing the city name"
            maxlength="60" 
            :value="citySearchInput" @input="citySearchInput=$event.target.value;getCities()" 
            ref="searchText"
            onfocus="value = ''" 
            @keydown.enter = 'enter'
            @keydown.down = 'down'
            @keydown.up = 'up' 
            @blur="handleBlur"
            @keyup.esc="handleBlur"
            :style="'width:' + (textFieldSize || 350) + 'px'" 
            />

        <div id="autocomplete-list" class="autocomplete-items">
            <div v-for="city in cities" :key="city" @mousedown.prevent @click="selectCity(city)" v-bind:class="{'autocomplete-active': isActive(city.id)}"> 
                <i class="fas fa-map-marker-alt fa-sm" v-bind:class="{'mapIconColorRed':isActive(city.id)}"></i>&nbsp;&nbsp;
                <b>{{getCityOnly(city.city)}}</b>,{{getOtherThanCity(city.city)}}
            </div>
            <div v-if="cities.length > 0 && searchSource=='google'" class="poweredByGoogle">
                <img src="../../assets/powered_by_google_on_white_hdpi.png" height="16" align="right"/>
            </div>
        </div>
        <div id="google-places-service" class="google-places-service"></div>
    </div>
</template>

<script>
import HTTP from '../../http-axios';
import { queryString } from "@/util.js";

export default {
	props: {
		criteria : {
			type: Object
		},
        textFieldSize : {
            type: Number
        },
        searchMode: {
            type: String
        }
    },
	data() {
		return {
            citySearchInput: '',
            citySearchInputCopy: '',
            cities: [],
            cityIndex: -1,
            worldCity: {
                id: 0,
                city: '',
                lat: 0.0,
                lng: 0.0,
                timezone: '',
                timeOffset: 0.0,
                timeOffsetMinutes: 0
            },
            searchSource: '',
            googleSessionToken: '',
            mode: this.searchMode || 'fallback'
		}
	},
	created() {
        // Initialize world city with parent input
        this.worldCity.city = this.criteria.location
        this.worldCity.lat = this.criteria.latitude
        this.worldCity.lng = this.criteria.longitude
        this.worldCity.timeOffset = this.criteria.timeOffset
        this.worldCity.timeOffsetMinutes = this.criteria.timeOffsetMinutes

        // Display the city given from  parent
        this.citySearchInput = this.worldCity.city

	},
    mounted() {
        // console.log('mode: ' + this.mode)
    },
	methods: {
        queryString: function (obj) {
            return queryString(obj);
        },

        createGoogleSessionToken: function() {
            // Create a new session token if not existing.
            // This token will be deleted when search is complete or abandoned
            if ( this.googleSessionToken ) {
                // Token already exists - a search is active / ongoing
                // console.log('old:st:' + JSON.stringify(this.googleSessionToken))
            } else {
                // Starting new search
                this.googleSessionToken = new google.maps.places.AutocompleteSessionToken();
                // console.log('new:st:' + JSON.stringify(this.googleSessionToken))
            }
        },

        getCities: function() {
            // To avoid redundant searches
            if ( this.citySearchInput != this.worldCity.city && 
                this.citySearchInputCopy != this.citySearchInput) {

                this.citySearchInputCopy = this.citySearchInput
                this.cityIndex = -1;

                // MODE - How the search widget works
                // 3 Possible modes [vac, google, fallback]
                if ( new String(this.mode).startsWith('google') ) {
                    // Google Places Data
                    this.getGoogleCities();
                } else if ( new String(this.mode).startsWith('fallback') ) {
                    // default search source - vac
                    // falls back to google if no results from vac database
                    this.getVACCities();
                } else {
                    // default search source - vac
                    this.getVACCities();
                }
            }
        },

        getVACCities: function() {
            this.searchSource = 'vac'
            HTTP().get(`/client/jamakkol/api/data/city/search?input=` + this.citySearchInput + '&year=' + this.criteria.year + '&month=' + this.criteria.month
                        + '&day=' + this.criteria.day + '&hour=' + this.criteria.hour + '&minute=' + this.criteria.minute + '&second=' + this.criteria.second)
            .then(response => {

                // search results - list of worrldCity
                this.cities = response.data
                
                // fallback search source - google
                // If no matching search results from VAC database for input search city text
                if ( new String(this.mode).startsWith('fallback') && this.cities && this.cities.length == 0 ) {
                    this.getGoogleCities();
                }

            })
            .catch(e => {
                console.log(e)
            })
        },

        getGoogleCities: function() {
            if ( this.citySearchInput ) {
                this.searchSource = 'google'
                const autoCompleteService = new google.maps.places.AutocompleteService();
                autoCompleteService.getPlacePredictions({ input: this.citySearchInput, sessionToken: this.createGoogleSessionToken(), types: ['(cities)'] }, this.displayGooglePredictions);                
            } else {
                // Search text is cleared / i.e. empty now
                this.cities = [];
            }
        },

        displayGooglePredictions(predictions, status) {
            // check for successful call results
            if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
                return;
            }

            if ( predictions.length > 0 ) {
                var googleCities = [];
                predictions.forEach((prediction) => {
                    var resultCity = { source: 'google', city: prediction.description, id: prediction.place_id, sessionToken: this.googleSessionToken }
                    googleCities.push(resultCity)
                    // console.log('rc:' + JSON.stringify(resultCity))
                });
                this.cities = googleCities;
            }
        },

        selectCity: function(vacWorldCity) {
            // Set selected city values depending on the search source
            if ( vacWorldCity.source && vacWorldCity.source == 'google') {
                // City Text *** IMPORTANT ***
                // We are keeping the prediction.description text
                // Not using the place.formatted_address text
                this.worldCity.city = vacWorldCity.city

                // console.log('google city is selected')
                const placesService = new google.maps.places.PlacesService(document.getElementById("google-places-service"));
                placesService.getDetails({ placeId: vacWorldCity.id, sessionToken: vacWorldCity.sessionToken, fields: ['formatted_address', 'geometry', 'utc_offset_minutes'] }, this.selectGoogleCity);

            } else {
                // console.log('vac city is selected')
                this.selectVACCity(vacWorldCity);
            }
        },

        selectVACCity: function(vacWorldCity) {
            // Set selected city values
            this.worldCity.id = vacWorldCity.id
            this.worldCity.city = vacWorldCity.city
            this.worldCity.lat = vacWorldCity.lat 
            this.worldCity.lng = vacWorldCity.lng
            this.worldCity.timezone = vacWorldCity.timezone
            this.worldCity.timeOffset = vacWorldCity.timeOffset
            this.worldCity.timeOffsetMinutes = vacWorldCity.timeOffset * 60

            // debug
            // console.log('wcsvac:city: ' + this.worldCity.city);
            // console.log('wcsvac:lat: ' + this.worldCity.lat);
            // console.log('wcsvac:lng: ' + this.worldCity.lng);
            // console.log('wcsvac:timeOffset: ' + this.worldCity.timeOffset);
            // console.log('wcsvac:timeOffsetMinutes: ' + this.worldCity.timeOffsetMinutes);

            // select the given city
            this.$refs.searchText.value = this.worldCity.city
            this.citySearchInput = this.worldCity.city

            // clear the cities results
            this.cities = [];

            // Reset cityIndex
            this.cityIndex = -1

            // Reset the Google Session Token
            this.googleSessionToken = '';

            // Get latest geocode coordinates for the selected city
            this.getGeocoding();
        },

        getGeocoding: function() {
            HTTP()
                .post("/client/jamakkol/api/data/city/geocode?" + this.queryString(this.worldCity))
                .then((response) => {
                    // console.log('gc-in: ' + JSON.stringify(this.worldCity))
                    var geocodingData = response.data;
                    this.worldCity.lat = geocodingData.lat 
                    this.worldCity.lng = geocodingData.lng
                    // console.log('gc-out: ' + JSON.stringify(geocodingData))

                    // emit selected city to parent
                    this.$emit('selectedWorldCity', this.worldCity)
                })
                .catch((e) => {
                    console.log(e);

                    // **** IMPORTANT **** : We may not have latest geocoding for the location
                    // emit selected city to parent
                    this.$emit('selectedWorldCity', this.worldCity)
                });
        },

        selectGoogleCity: function(place, status) {

            // check for successful call results
            if (status != google.maps.places.PlacesServiceStatus.OK || !place) {
                return;
            }
            
            // Set selected city coordinates
            if ( place && place.geometry ) {
                this.worldCity.lat = place.geometry.location.lat();
                this.worldCity.lng = place.geometry.location.lng();
                this.worldCity.timeOffsetMinutes = ( place.utc_offset_minutes );
                this.worldCity.timeOffset = ( place.utc_offset_minutes / 60.0 );

                // select the given city
                this.$refs.searchText.value = this.worldCity.city
                this.citySearchInput = this.worldCity.city

                // clear the cities results
                this.cities = [];

                // Reset cityIndex
                this.cityIndex = -1

                // Reset the Google Session Token
                this.googleSessionToken = '';

                // Get timezone data for this new city
                this.getTimezoneData();
            }
        },

        getTimezoneData: function() {
            HTTP()
                .post("/client/jamakkol/api/data/city/timezone?" + 
                        "city=" + this.worldCity.city + 
                        "&lat=" + this.worldCity.lat + 
                        "&lng=" + this.worldCity.lng + 
                        "&timeOffset=" + this.worldCity.timeOffset + 
                        "&timestamp=" + this.getCriteriaDate() + 
                        "&mode=" + this.mode)
                .then((response) => {
                    var timezoneData = response.data;
                    this.worldCity.timezone = timezoneData.timezone
                    this.worldCity.timeOffset = ( timezoneData.timeOffset )
                    this.worldCity.timeOffsetMinutes = ( this.worldCity.timeOffset * 60.0)

                    // console.log('tzData: ' + JSON.stringify(timezoneData))
                    // console.log('worldCity: ' + JSON.stringify(this.worldCity))

                    // emit selected city to parent
                    this.$emit('selectedWorldCity', this.worldCity)
                })
                .catch((e) => {
                    console.log(e);

                    // **** IMPORTANT **** : We may not have correct DST adjusted timeOffset
                    // emit selected city to parent
                    this.$emit('selectedWorldCity', this.worldCity)
                });
        },

        getCriteriaDate: function () {
            return (new Date(this.criteria.year, this.criteria.month - 1, this.criteria.day, this.criteria.hour, this.criteria.minute, 0).getTime() / 1000 );
        },

        getCityOnly: function( cityRegionCountry ) {
            if ( cityRegionCountry ) {
                if ( cityRegionCountry.indexOf(',') > -1 ) {
                    var segments = cityRegionCountry.split(',');
                    return segments.shift();
                } else {
                    return cityRegionCountry;
                }
            } else {
                return cityRegionCountry;
            }
        },

        getOtherThanCity: function( cityRegionCountry ) {
            if ( cityRegionCountry ) {
                if ( cityRegionCountry.indexOf(',') > -1 ) {
                    var segments = cityRegionCountry.split(',');
                    segments.shift();
                    return segments.join(", ")
                } else {
                    return '';
                }
            } else {
                return cityRegionCountry;
            }
        },

        // When enter pressed on the input
        enter() {
            if ( this.cities[this.cityIndex] ) {
                this.selectCity(this.cities[this.cityIndex])
            }
        },

        // When up pressed while cities are shown
        up(e) {

            // prevent default behaviour of moving the curson
            e.preventDefault()

            if( this.cityIndex > 0 ) {
                this.cityIndex--;
                this.citySearchInput = this.cities[this.cityIndex].city
            } else {
                this.cityIndex = this.cities.length
                this.citySearchInput = this.citySearchInputCopy
            }

            //console.log('up: ' + this.cityIndex)
        },

        // When down pressed while cities are shown
        down(e) {

            // prevent default behaviour of moving the curson
            e.preventDefault()

            if( this.cityIndex < (this.cities.length - 1) ) {
                this.cityIndex++;
                this.citySearchInput = this.cities[this.cityIndex].city
            } else {
                this.citySearchInput = this.citySearchInputCopy
                this.cityIndex = -1
            }

            //console.log('down: ' + this.cityIndex)
        },

        // For highlighting city during key up / down
        isActive(currentCityId) {
            if ( this.cities && this.cities.length > 0 ) {
                if ( this.cities[this.cityIndex] ) {
                    return this.cities[this.cityIndex].id == currentCityId;
                } else {
                    return false
                }
            } else {
                return false;
            }
        },

        handleBlur(e) {
            this.$refs.searchText.value = this.worldCity.city;            
            this.citySearchInput = this.worldCity.city;
            this.cities = []
            this.googleSessionToken = '';
        },

    },
}
</script>

<style scoped>
    .autocomplete {
        /*the container must be positioned relative:*/
        position: relative;
        display: inline-block;
    }

    input[type=text] {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 10px;  
        padding: 15px 15px;
        margin: 2px 0;
        box-sizing: border-box;
        border: 2px solid #087F8C;
        -webkit-transition: 0.5s;
        transition: 0.2s;
        outline: none;
    }

    input[type=text]:focus {
        border: 2px solid #ACD8AA;
    }

    .autocomplete-items {
        /*position the autocomplete items to be the same width as the container:*/
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 1000;
        width: 100%;
        top: 100%;
        left: 0;
        right: 0;
    }
    .autocomplete-items div {
        padding: 8px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
        font-size: 13px;
        width: 95%;
        box-shadow: 1px 1px 1px 1px #ACD8AA;
        text-align: left;
    }
    .autocomplete-items div:hover {
        /*when hovering an item:*/
        cursor: pointer;
    }
    .autocomplete-active {
        /*when navigating through the items using the arrow keys:*/
        background-color: #EDF6F9 !important;
    }
    .mapIconColorRed {
        color:red;
    }
    .google-places-service {
        width: 100%;
        text-align: center;
        align-content: center;
        align-items: center;
    }
    .poweredByGoogle {
        padding: 1px;
        vertical-align: middle;
        height: 20px;
    }    

</style>
<template>
		  <WorldCitySearchWidget 
        :searchMode="searchMode" 
        :criteria="criteria" 
        :textFieldSize="textFieldSize" />
</template>

<script>

import WorldCitySearchWidget from '@/components/widget-city-search/WorldCitySearchWidget'

export default {
  components: {WorldCitySearchWidget},
	props: {
		criteria : {
			type: Object
		},
    textFieldSize : {
        default: 300,
        type: Number
    },
    searchMode: {
        type: String,
        default: 'google'
    }
  },
	data() {
    return {
      searchModeOverride: ''
    }
	},
	created() {
	},	
	methods: {
	}
}
</script>

<style>

</style>
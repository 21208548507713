<template>
  <div id="app">

    <template v-if="$route.name">

      <TopNavBar
      :nav-links="navLinks"
      background="#344E41"
      link-color="#FFFFFF"
      hoverBackground="#FF9F1C"
      />

    </template>
    
    <router-view />
    
  </div>
</template>

<script>

import TopNavBar from '@/views/TopNavBar'

export default {
  components: {
    TopNavBar
  },
  data: () => ({
    navLinks: [
    // {
    //   text: 'FAQ',
		//   path: '/faq',
		//   icon: 'fas fa-question-circle fa-lg',
    //   fawIcon: ['fas', 'circle-question']
    // },
    {
      text: 'Contact',
		  path: '/contact',
		  icon: 'fas fa-envelope fa-lg',
      fawIcon: ['fas', 'envelope']
    }
    ]
  })
}
</script>

<style lang="scss">

figure {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 10px;
	margin-inline-end: 0;
}

body {
  margin: 0;
  background: #FBFBFF;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>

<template>
	<nav :style="{ background: background || '#333' }">
			<ul :style="{ background: background || '#333' }" ref="nav">
				<div class="nav-logo-display" @click="$router.push('home')">
					<img class="logo-image-style" src="../assets/logo.png" alt="Jamakkol" />
				</div>
				<router-link to="/contact" :style="{ color: linkColor || '#DDD' }" >
				<div @click="$router.push('contact')">
					<font-awesome-icon :icon="['fas', 'envelope']" size="lg"/>
				</div>
				</router-link>
			</ul>
	</nav>
</template>

<script>
export default {
	props: ['navLinks', 'background', 'linkColor', 'hoverBackground', 'imagePath'],
	data() {
		return {
		}
	},
	created() {
	},
	methods: {
	}
}
</script>

<style scoped lang="scss">

.logo-image-style {
	width: 215px;
	height: 63px;
}

.router-link-exact-active {
	color: white;
	background: #FF9F1C;
	padding: 7px;
}

.nav-logo-display {
	border: 0px solid;
	margin: auto;
	cursor: pointer;
}

nav {
	height: 65px;
	width: 100%;
	ul {
		display: flex;
		height: 100%;
		align-items: center;
		margin-block-start: 0;
		margin-block-end: 0;
		padding-inline-start: 0;
		box-shadow: 2px 2px 2px 2px #A3B18A;

		a {
			text-decoration: none;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			padding: 8px;
			border-radius: 10px;
			margin-right: 20px;
		}

		i {
			margin-right: 10px;
			font-size: 22px;
		}

		li {
			list-style-type: none;
			padding: 0px 12px 0px 12px;
		}
	}

	.fontAwesomeIcon {
		vertical-align: middle;
		pointer-events: none;
	}

	.nav-link-text {
		vertical-align: bottom;
	}

}

@media screen and (max-width: 800px) {

	nav {
		height: 65px;
		width: 100%;
		ul {
			display: flex;
			height: 100%;
			align-items: center;
			margin-block-start: 0;
			margin-block-end: 0;
			padding-inline-start: 0;
			box-shadow: 2px 2px 2px 2px #A3B18A;

			a {
				text-decoration: none;
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				padding: 2px;
				border-radius: 10px;
			}

			i {
				margin-right: 0px;
				font-size: 20px;
			}

			li {
				list-style-type: none;
				padding: 0px 5px 0px 5px;
			}
		}
	}
	}

@media print {
	nav {
		display: none;
	}
}
</style>

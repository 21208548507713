<template>

	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Jamakkol Prasanam is the Horary method of Vedic astrology, serving as a guiding light of clarity and counsel for myriad questions in life.  
			Practitioners harness the wisdom of Vedic astrology alongside the enigmatic influences of the Jama planets, navigating the cosmic currents with finesse. 
			Central to Jamakkol are three vital factors: the Udhayam heralds new beginnings, the Arudam signifies resolution, and the Kavippu weaves the intricate threads of destiny.
        	In this celestial dance, time emerges as both canvas and brushstroke, painting fate's contours with precision. 
			Each moment resonates with its unique significance, echoing the cosmic symphony. Through adept interpretation, Jamakkol unveils the hidden truths of existence, offering solace and guidance to seekers on their journey through life's mysteries.
		</h3>
		<p>
	        Jamakkol stands as a distinct manifestation within the realm of predictive arts, rooted in the rich tapestry of Vedic astrology. This specialized method, akin to prasanam or horary astrology, possesses a singular focus: to unveil the mysteries of specific queries through the prism of time.        
		</p>
		<p>
			Originating from the vibrant cultural milieu of South India, Jamakkol bears the imprints of tradition while embracing the contemporary nuances of astrological practice. Its practitioners, steeped in the lore of Vedic astrology, weave together the threads of ancient wisdom with the insights gleaned from the Jama planets, also known as the outer planets. These celestial bodies, beyond the confines of the traditional Vedic pantheon, lend their cosmic influence to the divinatory process, enriching it with their enigmatic energies.        
		</p>
		<p>
			Central to the efficacy of Jamakkol are three pivotal factors: the Udhayam, the Arudam, and the Kavippu. The Udhayam, symbolizing the dawn or the beginning, serves as the harbinger of new beginnings and possibilities, guiding the seeker towards auspicious paths. In contrast, the Arudam signifies culmination and resolution, offering clarity and closure to lingering queries. Lastly, the Kavippu, akin to the thread that binds the fabric of destiny, represents the intricate interplay of cosmic forces, shaping the course of events.        
		</p>
		<p>
			Within the intricate web of Jamakkol, time emerges as both the canvas and the brushstroke, painting the contours of fate with meticulous precision. Each moment carries its unique resonance, echoing the celestial symphony that orchestrates the dance of existence. Through the adept interpretation of planetary alignments and temporal nuances, Jamakkol unveils the veiled truths of existence, offering solace and insight to those who seek its guidance.        
		</p>
	</div>

	<div class="screen-wrapper">

		<div class="desktop-jamakkol-positions" v-if="deviceType=='BS'">
			<Jamakkol />
		</div>

		<div class="mobile-jamakkol-positions" v-if="deviceType=='SS'">
			<MobileJamakkol />
		</div>

	</div>


</template>

<script>
import Jamakkol from '@/components/Jamakkol'
import MobileJamakkol from '@/components/MobileJamakkol'

export default {
	components: { Jamakkol, MobileJamakkol },
	data() {
		return {
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},

	methods: {  
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		}
	}
  }
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-jamakkol-positions {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

.mobile-jamakkol-positions {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

</style>
